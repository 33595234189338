<template>
  <n-modal :show="data.show">
    <n-card
      style="width: 350px;"
      size="huge"
      :title="data.title"
      :segmented="{content: 'hard'}"
      align="center"
    >
      {{ data.body }}
      <br>
      <n-h2>
        {{ data.timer }}
      </n-h2>
      <br>
      <n-button @click="data.fn()">Baigti</n-button>
    </n-card>
  </n-modal>
</template>

<script>

import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    title: String,
    body: String,
    timer: Number,
    fn: Function,
    show: Boolean
  },
  setup (props) {
    const dataRef = ref({
      title: props.title,
      body: props.body,
      timer: props.timer,
      fn: props.fn,
      show: props.show
    })

    // lets watch if show prop is changed
    // then start count dow and start showing
    // the modal
    watch(() => props.show, (curr) => {
      console.log('watching', curr)
      if (curr) {
        dataRef.value.show = true
        countDownTimer()
      }
    })
    function countDownTimer() {
      // console.log('Counting down')
      if (dataRef.value.timer == 0) {
        props.fn()
      }
      if (dataRef.value.timer > 0) {
        setTimeout(() => {
          // console.log('counting')
          dataRef.value.timer -= 1
          countDownTimer()
        }, 1000)
      }
    }
    return {
      data: dataRef
    }
  }
})
</script>

<style scoped>
</style>
