<template>
  <n-layout content-style="padding: 16px" class="noselect">
    <countDownModal
      :fn="() => router.push({ path: '/auth' })"
      :timer="10"
      title="Jūsų slaptažodis sėkmingai pakeistas! ⏳"
      body="Netrukus jus perkelsime į prisijungimo langą."
      :show="data.successfulReset"
    />
    <br><br>
    <n-space vertical align="center">
      <n-card size="huge" title="Naujo slaptažodžio sukūrimas" :segmented="{content: 'hard'}">
        <n-form
          :label-width="80"
          :model="model"
          :rules="rules"
          size="medium"
          ref="formRef"
        >
          <n-form-item label="Naujas slaptažodis" path="password">
            <n-input-group>
              <n-input
                v-model:value="model.password"
                @input="handlePasswordInput"
                type="password"
                @keydown.enter.prevent
                placeholder=""
                show-password-on="mousedown"
              />
              <n-tooltip trigger="hover" :style="{ maxWidth: '400px' }">
                <template #trigger>
                  <n-button>?</n-button>
                </template>
                Slaptažodį privalo sudaryti bent 8 ženklai, jame privalo būti bent vienas skaičius ir didžioji raidė
              </n-tooltip>
            </n-input-group>
          </n-form-item>
          <n-form-item first label="Pakartokite slaptažodį" path="confirmPassword" ref="rPasswordFormItemRef">
            <n-input
              v-model:value="model.confirmPassword"
              type="password"
              @keydown.enter.prevent
              placeholder=""
            />
          </n-form-item>
        </n-form>
        <template #footer>
          <n-button @click="handleClick">Keisti slaptažodį</n-button>
        </template>
      </n-card>
    </n-space>
  </n-layout>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useMessage } from 'naive-ui'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { handleServerResponse } from '@/shared/serverResponseHandler'
import countDownModal from '@/components/CountDownModal.vue'

export default defineComponent({
  components: {
    countDownModal
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const dataRef = ref({successfulReset: false})
    const formRef = ref(null)
    const rPasswordFormItemRef = ref(null)
    const message = useMessage()
    const modelRef = ref({
      password: null,
      confirmPassword: null,
      token: router.currentRoute._value.query.token
    })
    function validatePasswordStartWith (rule, value) {
      return (
        modelRef.value.password &&
        modelRef.value.password.startsWith(value) &&
        modelRef.value.password.length >= value.length
      )
    }
    function validatePasswordSame (rule, value) {
      return value === modelRef.value.password
    }
    function validatePasswordComplexity (rule, value) {
      var hasNumber = /\d/;
      var hasUpperCase = /[A-Z]/
      var hasLowerCase = /[a-z]/
      return (
        value.length >= 8 &&
        hasNumber.test(value) &&
        hasUpperCase.test(value) &&
        hasLowerCase.test(value)
      )
    }
    return {
      formRef,
      rPasswordFormItemRef,
      model: modelRef,
      rules: {
        password: [
          {
            required: true,
            message: 'Privaloma užpildyti',
            trigger: ['input', 'blur']
          },
          {
            validator: validatePasswordComplexity,
            message: 'Slaptažodis per paprastas',
            trigger: 'input'
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: 'Privaloma užpildyti',
            trigger: ['input', 'blur']
          },
          {
            validator: validatePasswordStartWith,
            message: 'Slaptažodžiai nesutampa',
            trigger: 'input'
          },
          {
            validator: validatePasswordSame,
            message: 'Slaptažodžiai nesutampa',
            trigger: ['blur', 'password-input']
          }
        ]
      },
      handlePasswordInput () {
        if (modelRef.value.confirmPassword) {
          rPasswordFormItemRef.value.validate({ trigger: 'password-input' })
        }
      },
      handleClick (e) {
        e.preventDefault()
        formRef.value.validate((errors) => {
          // first check front end validation
          if (!errors) {
            // call auth api
            console.log('no errors lets call backend')
            store.dispatch('auth/resetPassword', modelRef.value)
              .then(resp => {
                const responseStatusMap = {
                  200: function() {
                    message.success(200)
                    message.success(resp.data.message, { duration: 8000 })
                    dataRef.value.successfulReset = true
                    // Object.keys(modelRef.value).forEach((i) => modelRef.value[i] = null)
                    // setTimeout(function() { router.push({ path: '/' }); }, 8000);
                  },
                  400: function() {
                    message.warning(400)
                    message.warning(resp.data.message, { duration: 8000 })
                  }
                }
                handleServerResponse(resp, responseStatusMap)
              })
          } else {
            console.log(errors)
            message.error('Nepavyko: patikrinkite įvesties laukelius')
          }
        })
      },
      data: dataRef,
      router: useRouter()
    }
  }
})
</script>

<style>
</style>
