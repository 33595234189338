export function handleServerResponse(response, responseStatusMap) {
  if (!response) {
    alert('The server did not respond')
    return
  }
  for (const status in responseStatusMap) {
    // mapping all the statuses to
    // the assigned functions
    if (response.status == status) {
      const fn = responseStatusMap[status]
      fn(response.data.message)
      return null
    }
  }
  // if none of the statuses hit, then do the following
  alert('This status is not assigned a function', response.status)
  alert(response.data.message)
}
